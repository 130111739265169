var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-card",
        { staticClass: "cardClassDetailForm", attrs: { title: "LBLDETAIL" } },
        [
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-md-4 col-lg-2" },
              [
                _c("c-label-text", {
                  attrs: { title: "LBL0001237", value: _vm.request },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-md-4 col-lg-2" },
              [
                _c("c-label-text", {
                  attrs: { title: "LBLPLANT", value: _vm.assessPlan.plantName },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-md-4 col-lg-2" },
              [
                _c("c-label-text", {
                  attrs: {
                    title: "LBL0000607",
                    value:
                      _vm.assessPlan.assessmentStartDate +
                      "~" +
                      _vm.assessPlan.assessmentEndDate,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-md-4 col-lg-2" },
              [
                _c("c-label-text", {
                  attrs: {
                    title: "LBL0001000",
                    value: _vm.assessPlan.assessmentYear,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-md-4 col-lg-2" },
              [
                _c("c-label-text", {
                  attrs: {
                    title: "LBL0001238",
                    value: _vm.assessPlan.ramAssessTypeName,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-md-12 col-lg-6" },
              [
                _c("c-label-text", {
                  attrs: {
                    title: "LBL0000367",
                    value: _vm.assessPlan.assessmentName,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-md-4 col-lg-2" },
              [
                _c("c-label-text", {
                  attrs: {
                    title: "LBL0001053",
                    value: _vm.assessPlan.regUserName,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-md-12 col-lg-12" },
              [
                _c("c-label-text", {
                  attrs: {
                    row: 11,
                    title: "LBL0001056",
                    value: _vm.assessPlan.remark,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
              [_c("c-upload", { attrs: { editable: false } })],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "c-card",
        { staticClass: "q-mt-sm", attrs: { title: "LBL0001240" } },
        [
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              { staticClass: "col-12" },
              _vm._l(_vm.assessPlan.teams, function (item, idx) {
                return _c("q-chip", {
                  key: idx,
                  attrs: {
                    outline: "",
                    square: "",
                    color: "primary",
                    "text-color": "white",
                    icon: "person_outline",
                    label: _vm.setTeamLabel(item),
                    title: _vm.setTeamLabel(item),
                  },
                  on: {
                    click: function ($event) {
                      _vm.truncate = !_vm.truncate
                    },
                  },
                })
              }),
              1
            ),
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("c-table", {
                  ref: "table3",
                  staticClass: "q-mt-sm",
                  attrs: {
                    title: "",
                    isTitle: false,
                    columns: _vm.grid3.columns,
                    gridHeight: _vm.grid3.height,
                    data: _vm.assessPlan.relatedDocuments,
                    filtering: false,
                    columnSetting: false,
                    usePaging: false,
                    editable: false,
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c("c-table", {
        ref: "table2",
        staticClass: "q-mt-sm",
        attrs: {
          title: "LBLLISTMEETING",
          columns: _vm.grid.columns,
          gridHeight: _vm.grid.height,
          data: _vm.assessPlan.conferences,
          filtering: false,
          columnSetting: false,
          usePaging: false,
          editable: _vm.editable,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }